import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import David from "../../images/judges/David.jpg";
import Funk from "../../images/judges/Funk.jpg";
import Moogli from "../../images/judges/Moogli.jpg";
import Natalie from "../../images/judges/Natalie.jpg";
import Obelixx from "../../images/judges/Obelixx.jpg";
import Paul from "../../images/judges/Paul.jpg";
import Del from "../../images/judges/Del.jpg";
import Adrienn from "../../images/judges/Adrienn.jpg";
import Joel from "../../images/judges/Joel.jpg";
import DJ from "../../images/DJ.jpg";
import Speaker from "../../images/speaker.png";
import Fade from "react-reveal/Fade";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
import { Collapse, IconButton } from "@material-ui/core";
import { ExpandMoreSharp } from "@material-ui/icons";
import JudgeSection from '../JudgeSection'
  const judges =[
    {image: David, text: ""},
      {image: Funk, text: ""},
        {image: Moogli, text: ""},
          {image: Natalie, text: ""},
            {image: Obelixx, text: ""},
              {image: Paul, text: ""},
                {image: Del, text: ""},
                  {image: Adrienn, text: ""},
                    {image: Joel, text: ""},
                                    {image: DJ, text: ""},
                  {image: Speaker, text: ""}
  ]
class App extends Component {
  constructor(props){
    super(props)
    this.state={collapsed: false}
  }
  render() {
    return (
        <JudgeSection fullscreen={true} judges={judges} title={"Dommere, DJ og speaker 2020"}></JudgeSection>
     
    );
  }
}

const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
