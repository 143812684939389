import React, { Component } from 'react';
import { connect } from 'react-redux'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { updateProductInCart, removeItem } from "../_actions/ShoppingCart"
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddShoppingCartSharp';
import PlusOneIcon from '@material-ui/icons/AddCircleOutlineSharp';
import MinusOneIcon from '@material-ui/icons/RemoveCircleOutlineSharp';
import ClearIcon from '@material-ui/icons/DeleteSharp';
import Img from 'react-image'
import PhotoIcon from '@material-ui/icons/PhotoCamera';
import { getEndpoint } from '../util/getEndpoint'
const styles = {
    container: {
        marginTop: 60,
        width: "20vw",
        overflowX: "hidden",
        maxHeight: "80vh"
    },

    shoppingBasketItemButton: {
        marginTop: -5,
        fontSize: 10,
    },
    shoppingBasketItemButtonClear: {
        position: "absolute",
        top: 0,
        right: 0
    },
    shoppingBasketItemQuantity: {
        width: 30,
        fontSize: 10,
    },
    controls: {
        display: "flex",
        flexDirection: "row",

        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: 60
    },
    listItem: {
        paddingBottom: 0
    },
    cardPicture:{
        width: 40,
        height: "auto"
    }
}
var timeout
class ShoppingCartEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleChange = async event => {
       await  this.setState({ ...this.state, quantity: event.target.value });
       window.clearTimeout(timeout);
        timeout = setTimeout( () => this.updateItem(), 500)

    };


    updateItem(quantity) {

        if(quantity){
            this.props.animation();
            this.props.updateProductInCart(this.props.product, Number(Number(this.props.product.quantity) + Number(quantity)))
            this.setState({ ...this.state, quantity: this.props.product.quantity})
        }else if (this.state.quantity) {
            this.props.animation();
            this.props.updateProductInCart(this.props.product, this.state.quantity)
            this.setState({ ...this.state, quantity: this.props.product.quantity})
        }
    }
    render() {
        return (
            <div>
                <ListItem style={styles.listItem}>
                    <IconButton style={styles.shoppingBasketItemButtonClear} color="inherit" aria-label="Menu" onClick={() => this.props.removeItem(this.props.product)}>
                        <ClearIcon > </ClearIcon>
                    </IconButton>
              
                    <Img
                    src={[
                        getEndpoint() + "/api/v1/images/getImage?bucketName=trygsenserviceimages&itemName=" +  this.props.product.productNumber  + ".png",
                    ]}
                    loader={<PhotoIcon style={styles.noImage}></PhotoIcon>}
                    unloader={<PhotoIcon style={styles.noImage}></PhotoIcon>}
                    style={styles.cardPicture}
                />


                   
                    <ListItemText primary={this.props.product.name} secondary={this.props.product.recommendedPrice ? this.props.product.recommendedPrice + " dkk" : "ingen pris"} />


                </ListItem>
                <div style={styles.controls}>
                <IconButton style={styles.shoppingBasketItemButton} color="inherit" aria-label="Menu" onClick={() => this.updateItem(-1)}>
                        <MinusOneIcon > </MinusOneIcon>
                    </IconButton>
                    <TextField
                        style={styles.shoppingBasketItemQuantity}
                        placeholder={this.props.product.quantity + ""}
                        onChange={this.handleChange}
                        value={this.state.quantity}
                        inputProps={{
                            style: { textAlign: "center" }
                        }}
                    />

                    <IconButton style={styles.shoppingBasketItemButton} color="inherit" aria-label="Menu" onClick={() => this.updateItem(1)}>
                        <PlusOneIcon > </PlusOneIcon>
                    </IconButton>

                </div>

            </div>




        );
    }
}

const mapStateToProps = state => ({
    products: state.ShoppingCart
})

const mapDispatchToProps = dispatch => ({
    updateProductInCart: (product, quantity) => dispatch(updateProductInCart(product, quantity)),
    removeItem: (product) => dispatch(removeItem(product)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartEntry)

