import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

class App extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100
        }}
      >
        <Typography variant="h2">Overnatning og mad</Typography>

        <Divider style={{ width: "100vw", marginTop: 20, marginBottom: 10 }} />
        <Typography variant="h3">Motel X</Typography>
        <a href="https://www.motelx.dk/">
          <img src="https://www.motelx.dk/assets/img/logo.png" />
        </a>
        <a href="https://www.motelx.dk/" > <Typography variant="h6">
          LINK
          </Typography> </a>
        <Typography variant="h6">
          Vi anbefaler motel X - god overnatning til fornuftige penge! 
        </Typography>
        <Divider style={{ width: "50vw", marginTop: 20, marginBottom: 10 }} />
        <Typography variant="h3">Randers Arena - mad indkøb</Typography>
        <a href="https://onlineshopweb.azurewebsites.net/ArenaRandersOnlineStaevnerMesser" >
          <img src="https://f.nordiskemedier.dk/292cvr2bhn89p9nb_1200_630_c_q90.jpg" style={{ width: 300 }} />
        </a>
        <a href="https://onlineshopweb.azurewebsites.net/ArenaRandersOnlineStaevnerMesser" > <Typography variant="h6">
          LINK Til mad indkøb
          </Typography> </a>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
