const navigation = (state = [], action) => {

    switch (action.type) {
      case 'NAVIGATE_PAGE':
         return Object.assign({}, state, {
          page: action.page,
        })
        case 'USER_LOGOUT':
        return Object.assign({}, state, {
         page: "FRONTPAGE",
       })
        case 'CLEAR_ALL':
        return {}
      default:
    
        return state
    }
  }
  
  export default navigation