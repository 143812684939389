import React, { Component, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import Navigator from './containers/Navigator'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-input-polyfill-react';
let theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
     root: {
        padding: "1vw"
      },
    },
  },

  palette: {
    primary: {
      main: "#37373E",
      contrastText: '#EDEEFF',
    },
    secondary: {
      main: '#B64A43',
      contrastText: '#EDEEFF',
    },
  },
});
theme = responsiveFontSizes(theme);
class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
      <Suspense fallback={""}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <Navigator></Navigator>
      </MuiPickersUtilsProvider>
      </Suspense>
      </MuiThemeProvider>
    );
  }
}

export default App;
