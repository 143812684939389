import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paul from "../../images/judges2023/Diogo Fernandes.jpg";
import Del from "../../images/judges2023/Fernando Lopes.jpg";
import Devon from "../../images/judges2023/Frank Da Costa.jpg";
import JDA from "../../images/judges2023/Gee.jpg";
import Meg from "../../images/judges2023/Gemini.jpg";
import Peter from "../../images/judges2023/Leslie King.jpg";
import Chris from "../../images/judges2023/Matthew Harding.jpg";
import Pedro from "../../images/judges2023/Pedro Teixeira.jpg";
import DJ from "../../images/DJ.jpg";
import Speaker from "../../images/speaker.png";
import Fade from "react-reveal/Fade";
import JudgeSection from '../JudgeSection'
  const judges =[
    {image: Paul, text: ""},
      {image: Del, text: ""},
        {image: Devon, text: ""},
          {image: JDA, text: ""},
            {image: Meg, text: ""},
              {image: Peter, text: ""},
                {image: Chris, text: ""},
                {image: Pedro, text: ""},
                  {image: DJ, text: ""},
                    {image: Speaker, text: ""}
  ]
class App extends Component {
  render() {
    return (
     
      <JudgeSection fullscreen={true} judges={judges} title={"Dommere, DJ og speaker 2023"}></JudgeSection>

    );
  }
}
const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
