import "isomorphic-fetch"
export function addProductToCart(product, quantity) {
    return async function(dispatch) {
      product.washUp = false; 
       dispatch(addProductToCartAction(product, quantity))
    }
}
export function updateProductInCart(product, quantity) {
    return async function(dispatch) {
      if(quantity < 1) return null;
       dispatch(updateProductInCartAction(product, quantity))
    }
}

export function removeItem(product) {
    return async function(dispatch) {
       dispatch(removeItemAction(product))
    }
}


export function clearCart() {
    return async function(dispatch) {
       dispatch(clearCartAction())
    }
}

const updateProductInCartAction = (product, quantity) => {
    return({
    type: 'SHOPPINGCART_UPDATEPRODUCT',
    product, 
    quantity
    })
  }
  
  const removeItemAction = (product) => {
    return({
    type: 'SHOPPINGCART_REMOVEITEM',
    product
    })
  }

  const clearCartAction = () => {
    return({
    type: 'SHOPPINGCART_CLEAR',
    })
  }

const addProductToCartAction = (product, quantity) => {
    return({
    type: 'SHOPPINGCART_ADDPRODUCT',
    product, 
    quantity
    })
  }