import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Fade from "react-reveal/Fade";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
import { Collapse, IconButton } from "@material-ui/core";
import { ExpandMoreSharp } from "@material-ui/icons";
class App extends Component {
  constructor(props){
    super(props)
    this.state={collapsed: this.props.open? this.props.open : false}
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100
        }}
      >
  
        <Typography variant="h2">{this.props.title}</Typography>
        <IconButton
          onClick={() => this.setState({collapsed: !this.state.collapsed})}
        >
          <ExpandMoreSharp/>
        </IconButton>
        <Divider style={{ width: "100vw" }} />
        <Collapse in={this.state.collapsed} imeout="auto" unmountOnExit>
       <BrowserView>
       {this.props.judges.map((judge, index) => {
           if(index %2 === 0){
            return (
                                   <Fade>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <img
            src={judge.image}
            style={{
              width: this.props.fullscreen? "100vw" :"50vw",
              minWidth: 300,
              paddingTop: 0,
              paddingBottom: 0
            }}
          />
          <Typography
            variant="h6"
            style={{
              width: "46vw",
              minWidth: 300,
              padding: "2vw",
              paddingTop: 0,
              paddingBottom: 0,
              alignSelf: "center"
            }}
          >
     {judge.text}
          </Typography>
          <Divider style={{ width: "100vw" }} />
        </div>
        </Fade>
            )
           }else{
            return (
        <Fade>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <Typography
            variant="h6"
            style={{
              width: "46vw",
              minWidth: 300,
              padding:  this.props.fullscreen? "0vw" :"2vw",
              paddingTop: 0,
              alignSelf: "center"
            }}
          >
  {judge.text}
          </Typography>
          <img
            src={ judge.image}
            style={{
              width: this.props.fullscreen? "100vw" :"50vw" ,
              minWidth: 300,
              paddingTop: 0,
              paddingBottom: 0
            }}
          />
        </div>
        <Divider style={{ width: "100vw" }} />
        </Fade>
            )
           }
       })}

        </BrowserView>
        <MobileView>
             {this.props.judges.map((judge, index) => {
                 return (
                       <Fade>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <img
            src={judge.image}
            style={{
              width: this.props.fullscreen? "100vw" :"50vw" ,
              minWidth: 300,
              paddingTop: 0,
              paddingBottom: 0
            }}
          />
          <Typography
            variant="h6"
            style={{
              width: "46vw",
              minWidth: 300,
              padding: "2vw",
              paddingTop: 0,
              paddingBottom: 0,
              alignSelf: "center"
            }}
          >
              {judge.text}
          </Typography>
          <Divider style={{ width: "100vw" }} />
        </div>
        </Fade>
                 )})}
        </MobileView>
        </Collapse>
      </div>
     
    );
  }
}

const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
