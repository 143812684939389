
const defaultState = {
  products: [

  ],
  productGroups: [

  ]
}

const products = (state = defaultState, action) => {
  console.log(action);
  switch (action.type) {
    case 'GETPRODUCTS_START':
      return Object.assign({}, state, {
        loading: true
      })
    case 'GETPRODUCTS_SUCCESS':
      return Object.assign({}, state, {
        loading: false,
        products: action.products.collection,
        productImageRef: action.images,
        productPDFRef: action.pdfReferences,
        productGroups: getProductGroups(action.products.collection),
        error: ""
      })

    case 'GETPRODUCTS_FAILED':
      return Object.assign({}, state, {
        loading: false,
        error: "Kunne ikke hente produkt katalog"
      })
    default:
      return state
  }
}


function getProductGroups(products) {
  var productList = {}

  for (var i = 0; i < products.length; i++) {
    var category = products[i].productGroup.name.split("-")[0];
    if (!productList[category]) {
      productList[category] = []
    }
    for (var j = 0; j < products.length; j++) {
      var comparer = products[j].productGroup.name.split("-")[0];
        if (category == comparer) {
          //Add to list of categories
          var shouldAdd = true;
          for (var k = 0; k < productList[category].length; k++) {
            if (productList[category][k] == products[j].productGroup.name.split("-")[1]) {
              shouldAdd = false;
            }
          }
          
          if (shouldAdd) {
            if (products[j].productGroup.name.split("-")[1]) {
              productList[category].push(products[j].productGroup.name.split("-")[1])
              productList[category].sort();
            } else {
             // productList[category].push(products[j].productGroup.name.split("-")[0])
            }
          }
        }
    }
  }

  const ordered = {};
  Object.keys(productList).sort().forEach(function (key) {
    ordered[key] = productList[key];
  });


  console.log(ordered)

  return ordered
}
export default products
