const todos = (state = [], action) => {
    switch (action.type) {
          case 'OPENORDERS_GET_START':
          return Object.assign({}, state, {
              loading: action.loading
        })
        case 'OPENORDERS_GET_SUCCESS':
        return Object.assign({}, state, {
          openOrders: action.orders,
          loading: action.loading
        })
        case 'OPENORDERS_GET_FAILED':
        return Object.assign({}, state, {
          loading: action.loading
     })
      case 'ORDERS_GET_START':
      return Object.assign({}, state, {
          loading: action.loading
     })
     case 'ORDERS_GET_SUCCESS':
     return Object.assign({}, state, {
      orders: action.orders,
      loading: action.loading
    })
    case 'ORDERS_GET_FAILED':
    return Object.assign({}, state, {
      loading: action.loading
   })
   case 'INVOICES_GET_START':
   return Object.assign({}, state, {
       loading: action.loading
  })
  case 'INVOICES_GET_SUCCESS':
  return Object.assign({}, state, {
   invoices: action.orders.collection,
   loading: action.loading
 })
 case 'INVOICES_GET_FAILED':
 return Object.assign({}, state, {
   loading: action.loading
})

case 'TEMPINVOICES_GET_START':
return Object.assign({}, state, {
    loading: action.loading
})
case 'TEMPINVOICES_GET_SUCCESS':
return Object.assign({}, state, {
tempInvoices: action.orders.collection,
loading: action.loading
})
case 'TEMPINVOICES_GET_FAILED':
return Object.assign({}, state, {
loading: action.loading
})
case 'OPENORDERS_ACTION_START':
return Object.assign({}, state, {
  loadingAction: action.loadingAction
})
case 'OPENORDERS_ACTION_SUCCESS':
return Object.assign({}, state, {
  loadingAction: action.loadingAction
})
case 'OPENORDERS_ACTION_FAILED':
return Object.assign({}, state, {
  loadingAction: action.loadingAction
})
      default:
        return state
    }
  }
  
  export default todos