import { combineReducers } from 'redux'
import Template from './Template'
import Navigation from './Navigation'
import Products from './Products'
import ShoppingCart from './ShoppingCart'
import Checkout from './Checkout'
import Users from './Users'
import Orders from './Orders'
import SignUpDancers from './SignUpDancers'
import pdf from './pdf'


export default combineReducers({
    rootReducer,
    Navigation,
    Products,
    ShoppingCart,
    Checkout,
    Users,
    Orders,
    pdf,
    SignUpDancers,
    Template
})

const rootReducer = (state, action) => {
  
  if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return combineReducers(state, action);
};