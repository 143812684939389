import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Adrienn from '../images/judges2022/Adrienn.png'
import Antonella from '../images/judges2022/Antonella Place-1.png'
import Biggreg from '../images/judges2022/Biggreg Place-1.png'
import Joel from '../images/judges2022/Joel Place-1.png'
import Leslie from '../images/judges2022/Leslie Place-1.png'
import Paul from '../images/judges2022/Paul Place-1.png'
import Peter from '../images/judges2022/Peter Place-1.png'
import Seuline from '../images/judges2022/Seuline Place-1.png'
import Vitor from '../images/judges2022/Vitor Place-1.png'
import DJ from "../images/DJ.jpg";
import Speaker from "../images/speaker.png";
import Fade from "react-reveal/Fade";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import TwentyTwenty from '../components/oldjudges/2020.js'
import TwentyTwentyOne from '../components/oldjudges/2021.js'
import TwentyTwentyThree from '../components/oldjudges/2023.js'
import JudgeSection from '../components/JudgeSection'
  const judges =[
    {image: Paul, text: ""},
      {image: Peter, text: ""},
        {image: Adrienn, text: ""},
          {image: Antonella, text: ""},
            {image: Biggreg, text: ""},
                {image: Joel, text: ""},
                  {image: Leslie, text: ""},
                    {image: Seuline, text: ""},
                     {image: Vitor, text: ""},
                         {image: DJ, text: ""},
                             {image: Speaker, text: ""}
  ]

class App extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100
        }}
      >
         <TwentyTwentyThree></TwentyTwentyThree>
              <JudgeSection fullscreen={true} judges={judges} title={"Dommere, DJ og speaker 2022"}></JudgeSection>
          <TwentyTwentyOne></TwentyTwentyOne>
        <TwentyTwenty></TwentyTwenty>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
