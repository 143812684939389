const defaultState = {
    step: 0
}

const Checkout = (state =defaultState, action) => {
    switch (action.type) {
      case 'CHECKOUT_STEPPER_SET':
      return Object.assign({}, state, {
        step: action.step
      })
      case 'CHECKOUT_ADDDATA':
      var key =   Object.keys(action.data)[0]
      var value =  Object.values(action.data)[0]
      return Object.assign({}, state, {

        userInfo: {
            ...state.userInfo,
            [key]: value
        }
      })
      case 'USER_LOGIN_SUCCESS':
      var  userType = "private"; 
      if( action.user[0].userType === 1){
        userType = "private"
      }else if(action.user[0].userType === 2){
        userType = "ean"
      }else if(action.user[0].userType === 3){
        userType = "firm"
      }
    
      return Object.assign({}, state, {

        
        userInfo: {
           userType: userType,
           name: action.user[0].name,
           phone: action.user[0].phone,
           address: action.user[0].address,
           postal: action.user[0].postal,
           town: action.user[0].town,
           email: action.user[0].email
        }
      })
      case 'USER_UPDATE_SUCCESS' :
      return Object.assign({}, state, {
        userInfo: {
          ...state.userInfo,
           name: action.user.name,
           phone: action.user.phone,
           address: action.user.address,
           postal: action.user.postal,
           town: action.user.town,
           email: action.user.email
        }
      })
      case 'SHOPPINGCART_UPDATEORDER':
      console.log(action.orderInfo)
      if(!action.orderInfo.delivery){
        action.orderInfo.delivery = ""
      }

      var pay = action.pay


      return Object.assign({}, state, {
        userInfo: { 
           ...action.orderInfo.DBRESULT.order,
           confirm: true,
           keepOpen: action.pay ? false : action.orderInfo.DBRESULT.order.keepOpen,
           orderNr:   action.orderNr,
           comment: action.orderInfo.notes?  action.orderInfo.notes.textLine2: "",
           delivDate: action.orderInfo.delivery ? action.orderInfo.delivery.deliveryDate?  action.orderInfo.delivery.deliveryDate: "" : "",
           partyDate: action.orderInfo.dueDate,
           name:  action.orderInfo.recipient.name,
           address:  action.orderInfo.recipient.address,
           postal: action.orderInfo.recipient.zip,
           town:  action.orderInfo.recipient.city,
           email:  action.orderInfo.email,
           phone:  action.orderInfo.phone,
           pay
        }
      }) 

      case 'SHOPPINGCART_CLEAR':
      return Object.assign({}, state, {
        userInfo: {
          ...state.userInfo,
          orderNr: "",
          pay: false
        }
        }) 
      case 'CHECKOUT_SUCCESS':
      return Object.assign({}, state, {
        checkoutSuccess: action.checkoutSuccess,
        checkoutLoading: action.checkoutLoading
      }) 
      case 'CHECKOUT_FAILED':
      return Object.assign({}, state, {
        checkoutSuccess: action.checkoutSuccess,
        checkoutLoading: action.checkoutLoading
      }) 
      case 'CHECKOUT_START':
      return Object.assign({}, state, {
        checkoutSuccess: action.checkoutSuccess,
        checkoutLoading: action.checkoutLoading
      }) 
      case 'CLEAR_ALL':
      return Object.assign({}, state, {
        step: 0,
        checkoutSuccess: null,
        orderNr: "",
        pay: false
      }) 
      default:
        return state
    }
  }
  
  export default Checkout