import React, { Component } from 'react';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createUser, login } from '../_actions/User'
import Typography from '@material-ui/core/Typography';
import { stat } from 'fs';
import ReactLoading from 'react-loading';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
const styles = {
  container: {
    width: "30vw",
    minWidth: 280,
    padding: 15,
    paddingTop: 25,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column"
  },
  sideBySideContainer: {
    marginLeft: 0,
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "row"
  },
  input: {
    margin: 2,
    width: "25vw",
    minWidth: 250,
  },
  town: {
    margin: 2,
    width: "15vw",
    minWidth: 145,
  },
  postal: {
    margin: 2,
    width: "10vw",
    minWidth: 85,
  },
  button: {
      margin: 5,
      width: "25vw",
      minWidth: 250,
  },
  messageBar:{
    marginTop: 10,
    height: 30
  },
  typeSelect:{
    marginBottom: 10, 
    position: "absolute",
    top: -10
  }
}


class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {user:{}}
  }
  handleChange = name => event => {
    console.log(event.target.value)
    console.log(name)
    this.setState({
      user: {
        ...this.state.user,
        [name]: event.target.value
      }
    });
  };

  render() {
    return (
      <div style={styles.container}>
       <Typography style={{margin: 25, fontWeight: "bold", width: "80%"}}>Hvis du har købt ved Trygsen service før har du allerede en bruger, gå til login og klik på nyt kodeord for at få et nyt kodeord</Typography>
          
          <FormControl >
          <InputLabel shrink style={styles.typeSelect}>
            Type af bruger
          </InputLabel>

        <Select
          value={this.state.user.type}
          style={styles.input}
          onChange={this.handleChange('type')}
          input={<Input name="age" id="age-label-placeholder" />}
          displayEmpty
        >
       
          <MenuItem value={1}>Privat</MenuItem>
          <MenuItem value={2}>Institution/forening</MenuItem>
          <MenuItem value={3}>Erhverv</MenuItem>
        </Select>
        </FormControl>
        <TextField
          id="standard-name"
          label="Navn"
          value={this.state.navn}
          onChange={this.handleChange('name')}
          margin="normal"
          style={styles.input}
        />
        <TextField
          id="standard-name"
          label="Telefon nummer"
          value={this.state.phone}
          onChange={this.handleChange('phone')}
          margin="normal"
          style={styles.input}
        />
        <TextField
          id="standard-name"
          label="Adresse"
          value={this.state.address}
          onChange={this.handleChange('address')}
          margin="normal"
          style={styles.input}
        />
        <div style={styles.sideBySideContainer}>
          <TextField
            id="standard-name"
            label="By"
            value={this.state.town}
            onChange={this.handleChange('town')}
            margin="normal"
            style={styles.town}
          />
          <TextField
            id="standard-name"
            label="post kode"
            value={this.state.postal}
            onChange={this.handleChange('postal')}
            margin="normal"
            style={styles.postal}
          />
        </div>
        <TextField
          id="standard-name"
          label="Email"
          value={this.state.email}
          onChange={this.handleChange('email')}
          margin="normal"
          style={styles.input}
        />
        <TextField
          id="standard-name"
          label="Kodeord"
          value={this.state.password}
          onChange={this.handleChange('password')}
          margin="normal"
          type="password"
          style={styles.input}
        />
        <TextField
          id="standard-name"
          label="bekræft kodeord"
          type="password"
          value={this.state.confirmPassword}
          onChange={this.handleChange('confirmPassword')}
          margin="normal"
          style={styles.input}
        />

        <div style={styles.messageBar}>
          {this.props.loading ?
            <ReactLoading type={"spin"} color={"rgb(200,200,200)"} height={25} width={25} />
            :
            <Typography variant="h6">  {this.props.msg}</Typography>

          }
        </div>
        <Button style={styles.button} variant="contained" size="large" color="primary" onClick={() => this.props.createUser(this.state.user)}>
          {"Opret Bruger"}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  msg: state.Users.msg ? state.Users.msg : "",
  loading: state.Users.loading
})

const mapDispatchToProps = dispatch => ({
  createUser: user => dispatch(createUser(user)),
  login: (user, navigate) => dispatch(login(user, navigate)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup)

