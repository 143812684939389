import React, { Component } from 'react';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { login, newPassword } from '../_actions/User'
import Typography from '@material-ui/core/Typography';
import { stat } from 'fs';
import ReactLoading from 'react-loading';
import { navigateToPage } from '../_actions/Navigation'
const styles = {
    container: {
        width: "30vw",
        minWidth: 280,
        padding: 15,
        marginBottom: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
        height: "100%"
      },
      sideBySideContainer: {
        marginLeft: 0,
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "row"
      },
      input: {
        margin: 2,
        width: "25vw",
        minWidth: 250,
      },
      town: {
        margin: 2,
        width: "15vw",
        minWidth: 145,
      },
      postal: {
        margin: 2,
        width: "10vw",
        minWidth: 85,
      },
      button: {
          margin: 5,
          width: "25vw",
          minWidth: 250,
      },
      messageBar:{
        marginTop: 10,
        textAlign: "center"
      },
    buttonContainer:{
        display: "flex",
        flex: 1,
        flexFlow: "column"

    }
}


class Signin extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    loginAndStay () {
        this.props.login(this.state.user)
    }

    loginAndGo () {
     
        this.props.login(this.state.user, true)
    }
    newPassword () {
        this.props.newPassword(this.state.user)
    }
    
    handleChange = name => event => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.value
            }
        });
    };

    render() {
        return (
            <div style={styles.container}>
            <Typography style={{margin: 25, fontWeight: "bold", width: "80%"}}>Hvis du har købt ved Trygsen service før har du allerede en bruger, gå til login og klik på nyt kodeord for at få et nyt kodeord</Typography>
    
                <TextField
                    id="standard-name"
                    label="Email"
                    value={this.state.navn}
                    onChange={this.handleChange('email')}
                    margin="normal"
                    style={styles.input}
                />
                <TextField
                    id="standard-name"
                    label="Kodeord"
                    type="password" 
                    value={this.state.phone}
                    onChange={this.handleChange('password')}
                    margin="normal"
                    style={styles.input}
                />

                <div style={styles.messageBar}>
                    {this.props.loading ?
                        <ReactLoading type={"spin"} color={"rgb(200,200,200)"} height={25} width={25} />
                        :
                        <Typography variant="h7">  {typeof this.props.msg == "string" ? this.props.msg: ""}</Typography>

                    }
                </div>
                <div style={styles.buttonContainer}>
                    <Button style={styles.button} variant="contained" size="medium" color="primary" onClick={() => this.loginAndStay()}>
                        {"Login og bliv på siden"}
                    </Button>
                    <Button style={styles.button} variant="contained" size="medium" color="primary" onClick={() => this.loginAndGo()}>
                        {"login og gå til bruger siden"}
                    </Button>
                    <Button style={styles.button} variant="contained" size="medium" color="secondary" onClick={() =>this.newPassword()}>
                        {"Nyt kodeord"}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    msg: state.Users.loginmsg ? state.Users.loginmsg : "",
    loading: state.Users.loading
})

const mapDispatchToProps = dispatch => ({
    login: (user, navigate) => dispatch(login(user, navigate)),
    newPassword:(user) => dispatch(newPassword(user)),
    navigate: page => dispatch(navigateToPage(page)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signin)

