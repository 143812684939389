import React, { Component } from "react";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { clearCart } from "../_actions/ShoppingCart";
import ShoppingCartEntry from "./ShoppingCartEntry";
import Typography from "@material-ui/core/Typography";
import { navigateToPage } from "../_actions/Navigation";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
const styles = {
  container: {
    marginTop: 80,
    width: 310,
    overflowX: "hidden",
    maxHeight: "75vh"
  },
  buttonContainer: {
    marginTop: 5,

    display: "flex",
    flex: 1,
    flexFlow: "row"
  },
  totalContainer: {
    marginTop: 5,
    marginLeft: 10
  },
  shoppingBasketItemButtonOrder: {
    width: 180,
    fontSize: 10,
    borderRadius: 0
  },
  shoppingBasketItemButtonClear: {
    width: 130,
    fontSize: 10,
    borderRadius: 0
  },
  shoppingBasketItemQuantity: {
    width: 40,
    fontSize: 10
  },
  controls: {
    display: "flex",
    flexDirection: "row",

    justifyContent: "flex-end",
    alignItems: "flex-end",
    margin: 5
  },
  closeButton: {
    position: "absolute",
    top: 55,
    zIndex: 1000
  },
  icon: {
    fontSize: 30
  }
};
class ShoppingBasket extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getSubTotal = this.getSubTotal.bind(this);
  }

  getSubTotal() {
    var total = 0;
    for (var i = 0; i < this.props.products.length; i++) {
      total +=
        this.props.products[i].recommendedPrice *
        this.props.products[i].quantity;
    }

    return total;
  }

  updateParent() {}

  render() {
    return (
      <div>
        <IconButton
          color="inherit"
          style={styles.closeButton}
          onClick={() => this.props.toggleShoppingBasket()}
          aria-label="Close"
        >
          <CloseIcon style={styles.icon} />
        </IconButton>
        <div style={styles.container}>
          <List>
            {this.props.products.map((product, index) => {
              return (
                <div>
                  <ShoppingCartEntry
                    animation={this.props.animation}
                    product={product}
                    key={index}
                  />
                  <Divider variant="inset" />
                </div>
              );
            })}
          </List>
        </div>
        <Divider />
        <div style={styles.totalContainer}>
          <Typography variant="h6" gutterBottom>
            Subtotal: {this.getSubTotal()} dkk
          </Typography>
        </div>
        <Divider />
        <div style={styles.buttonContainer}>
          <Button
            style={styles.shoppingBasketItemButtonClear}
            variant="contained"
            size="large"
            color="primary"
            onClick={() => this.props.clearCart()}
          >
            {"Ryd"}
          </Button>
          <Button
            style={styles.shoppingBasketItemButtonOrder}
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
              this.props.toggleShoppingBasket();
              window.history.pushState("", "", "/CHECKOUT");
              this.props.navigate("CHECKOUT");
            }}
          >
            {this.props.updateMode ? "Gem ændringer" : "Bestil"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.ShoppingCart.products,
  updateMode: state.ShoppingCart.updateMode,
  cart: state.ShoppingCart
});

const mapDispatchToProps = dispatch => ({
  clearCart: (product, quantity) => dispatch(clearCart(product, quantity)),
  navigate: page => dispatch(navigateToPage(page)),
  clearShoppingCart: () => dispatch(clearCart())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBasket);
