

const todos = (state = [], action) => {
  switch (action.type) {
    case "SIGNUPDANCERS_ADDDATA":
      var key = Object.keys(action.data)[0];
      var value = Object.values(action.data)[0];
      return Object.assign({}, state, {
        danceTeamInfo: {
          ...state.danceTeamInfo,
          [key]: value
        }
      });
    case "SIGNUPDANCERS_ADDTEAM_REQUEST":
      return Object.assign({}, state, {
        loading: true
      });
    case "SIGNUPDANCERS_ADDTEAM_SUCCESS":
      return Object.assign({}, state, {
        loading: false,
        message: "success! du er nu tilmeldt med dette danse hold, du kan se dine tilmeldinger under din profil under menupunktet Danse hold. Det er også her at du skal uploade din musik"
      });
    case "SIGNUPDANCERS_ADDTEAM_FAILURE":
      return Object.assign({}, state, {
        loading: false,
        message: "der gik noget galt! prøv igen eller kontakt os for hjælp"
      });
    case "SIGNUPDANCERS_CLEARMESSAGE":
      return Object.assign({}, state, {
        loading: false,
        message: ""
      });
    case "SIGNUPDANCERS_GETTEAM_REQUEST":
      return Object.assign({}, state, {
        loading: false,
        message: ""
      });

    case "SIGNUPDANCERS_GETTEAM_SUCCESS":
      return Object.assign({}, state, {
        loading: false,
        danceSignups: action.data
      });
    case "SIGNUPDANCERS_GETTEAM_FAILURE":
      return Object.assign({}, state, {
        loading: false,
        danceSignups: []
      });
      case "SIGNUPDANCERS_CLEAR":
        return Object.assign({}, state, {
          loading: false,
          danceSignups: []
        });

    case "RESET_APP": 
    return Object.assign({}, state, {
      loading: false,
      danceSignups: []
    });
    default:
      return state;
  }
};

export default todos;
