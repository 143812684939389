import React, { Component } from "react";
import { connect } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paul from "../../images/judges2021/Paul JPEG.jpg";
import Del from "../../images/judges2021/Del Mak JPEG.jpg";
import Devon from "../../images/judges2021/Devon JPEG.jpg";
import JDA from "../../images/judges2021/JDA JPEG.jpg";
import Meg from "../../images/judges2021/Meg JPEG.jpg";
import Peter from "../../images/judges2021/Peter JPEG.jpg";
import Chris from "../../images/judges2021/Chris JPEG.jpg";
import DJ from "../../images/DJ.jpg";
import Speaker from "../../images/speaker.png";
import Fade from "react-reveal/Fade";
import JudgeSection from '../JudgeSection'
  const judges =[
    {image: Paul, text: ""},
      {image: Del, text: ""},
        {image: Devon, text: ""},
          {image: JDA, text: ""},
            {image: Meg, text: ""},
              {image: Peter, text: ""},
                {image: Chris, text: ""},
                  {image: DJ, text: ""},
                    {image: Speaker, text: ""}
  ]
class App extends Component {
  render() {
    return (
     
      <JudgeSection fullscreen={true} judges={judges} title={"Dommere, DJ og speaker 2021"}></JudgeSection>

    );
  }
}
const mapStateToProps = state => ({
  // todos: getVisibleTodos(state.todos, state.visibilityFilter)
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
