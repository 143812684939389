import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './_reducers'
import { Provider } from 'react-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import thunkMiddleware from 'redux-thunk'
import ErrorBoundary from './components/ErrorHandler'
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['Navigation']
}


const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer,
  {},
  compose(
    applyMiddleware(thunkMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  ))

let persistor = persistStore(store)
const container = document.getElementById('root');
const root = ReactDOM.unstable_createRoot(container);
root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
