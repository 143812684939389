import { getEndpoint } from '../util/getEndpoint'
import "isomorphic-fetch"
export function getProducts() {
  return async function (dispatch) {
    dispatch(startGettingProducts);
    return fetch(getEndpoint() + '/api/v1/webshop/getProducts', {
      method: 'get',
      mode: "cors"
    })
      .then(
        async function (response) {
          try {
            var json = await response.json();
           
            //Get all product images

               fetch(getEndpoint() + "/api/v1/images/getImageList", {
                method: 'get',
                mode: "cors"
              })
                .then(
                  async function (response) {
                    try {
                      response.json().then(images => {
     
                        var imageReferences = []; 
                        for (var i = 0; i < images.length; i++){
                          
                            var image = [];
                            var split = images[i].split("."); 
                            //150   .    jpg

                            for (var j = 0; j < images.length; j++){
                              try{
                              var numb =  images[j].match(/\d/g);
                              numb = numb.join("");
                              if(split[0] == numb){
                                image.push (images[j]);
                              }
                            }catch(e){
                            
                            }
                          }
                          imageReferences[split[0]] = image
                        }
                        for (var i = 0; i < imageReferences.length; i++){
                          if (imageReferences[i] == null){
                            imageReferences[i] = []
                          }
                        }

                         fetch(getEndpoint() + "/api/v1/images/getPDFList", {
                          method: 'get',
                          mode: "cors"
                        })
                          .then(
                            async function (response) {
                              try {
                                response.json().then(pdfs => {
                                  var pdfReferences = []; 
                                  for (var i = 0; i < pdfs.length; i++){
                                    
                                      var pdf = [];
                                      var split = pdfs[i].split(" - "); 
                                      //150 - hej.pdf
                                      if( pdfReferences[split[0]]){
                                        pdfReferences[split[0]].push(pdfs[i])
                                      }else{
                                        pdfReferences[split[0]] = [pdfs[i]]
                                      }
                                     
                                  }
                                  for (var i = 0; i < imageReferences.length; i++){
                                    if (pdfReferences[i] == null){
                                      pdfReferences[i] = []
                                    }
                                  }
                                  dispatch(successfullyGotProducts(json, imageReferences, pdfReferences))
                                });
                                //Get all product images
                              } catch (e) {
                            
                                console.log(e);
                              }
                            },
          
                            error => console.log(error)
                          )
                      });
                      //Get all product images
                    } catch (e) {
                      console.log("error!!")
                      console.log(e);
                    }
                  },

                  error => console.log(error)
                )
                
               

          } catch (e) {
            console.log("error!!")
            console.log(e);
          }
        },

        error => dispatch(failedToGetProducts(error))
      )
  }

}

const startGettingProducts = () => ({
  type: 'GETPRODUCTS_START',
  loading: true
})

const successfullyGotProducts = (products, images, pdfReferences) => ({
  type: 'GETPRODUCTS_SUCCESS',
  products,
  images,
  pdfReferences
})

const failedToGetProducts = error => ({
  type: 'GETPRODUCTS_FAILED',
  error
})
