
const defaultState = {
    products: [

    ]
}


const ShoppingCart = (state = defaultState, action) => {
    switch (action.type) {
        case 'SHOPPINGCART_ADDPRODUCT':

            for (var i = 0; i < state.products.length; i++) {
                if (state.products[i].productNumber === action.product.productNumber) {

                    state.products[i].quantity = Number(state.products[i].quantity) + Number(action.quantity);
                    return Object.assign({}, state, {
                        ...state,
                        products: [
                            ...state.products,
                        ]
                    })
                }
            }
            return Object.assign({}, state, {
                ...state,
                products: [
                    ...state.products,
                    addQuanity(action.product, action.quantity)
                ]
            })
        case 'SHOPPINGCART_UPDATEPRODUCT':

            for (var i = 0; i < state.products.length; i++) {
                if (state.products[i].productNumber === action.product.productNumber) {

                    var toAdd = state.products[i];
                    state.products[i].quantity = Number(action.quantity);
                    return Object.assign({}, state, {
                        ...state,
                        products: [
                            ...state.products,
                        ]
                    })
                }
            }
            return Object.assign({}, state, {
                ...state,
                products: [
                    ...state.products,
                    addQuanity(action.product, action.quantity)
                ]
            })
        case 'SHOPPINGCART_REMOVEITEM':
            var products = removeProduct(state.products, action.product)
            return Object.assign({}, state, {
                ...state,
                products

            })
        case 'SHOPPINGCART_UPDATEORDER':
        var pay = action.pay; 
            return Object.assign({}, state , {
                updateMode: action.pay? false: true, 
                orderNr: action.orderNr,
                products: action.products,
                pay
                
            })
        case 'SHOPPINGCART_CLEAR':
            return Object.assign({}, {}, {
                products: [

                ]
            })
        case 'CLEAR_ALL':
            return defaultState
        default:
            return state
    }
}

function addQuanity(product, quantity) {
    console.log(product);
    console.log(quantity);
    product.quantity = quantity;
    return product;
}

function removeProduct(products, product) {
    console.log({ products, product })
    for (var i = 0; i < products.length; i++) {
        if (products[i].name === product.name) {
            products.splice(i, 1)
        }
    }
    return products

}

export default ShoppingCart