const todos = (state = [], action) => {
    switch (action.type) {
        case 'SET_PDFDATA':
        return Object.assign({}, state, {
           data: action.data
          })
      default:
        return state
    }
  }
  
  export default todos