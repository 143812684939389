import {getEndpoint} from '../util/getEndpoint'
import {navigateToPage} from './Navigation'
import "isomorphic-fetch"
export function updateUser(user) {
    console.log(user)
    return async function(dispatch) {
      dispatch(updateUserStart());
        //Create userø
        if(!user){
            dispatch(updateUserFailed("udfyld venligst alle felter"));
            return;
        }
        var check = !(user.name && user.email && user.address && user.phone && user.postal&& user.town && user.customerNr);
        console.log({check})
        if(check){
            dispatch(updateUserFailed("udfyld venligst alle felter"));
            return;
        }

        fetch(getEndpoint() + "/api/v1/user/updateUser", {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(user),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
          })
            .then(
              async function (response) {
                try {
                  response.json().then(json => {
                    console.log(json);
                    dispatch(updateUserSuccess(json.msg, user));
                  });
                } catch (e) {
                  console.log("error!!")
                  dispatch(updateUserFailed(e));
                }
              },

              error =>  dispatch(updateUserFailed(error))
            )

     
    }
}

export function changePassword(user) {
  console.log(user)
  return async function(dispatch) {
    dispatch(updatePasswordStart())
      //Create userø
      if(!user){
          dispatch(updatePasswordFailed("udfyld venligst alle felter"));
          return;
      }
      var check = !(user.oldPass && user.newPass && user.confirmPass);
      console.log({check})
      if(check){
          dispatch(updatePasswordFailed("udfyld venligst alle felter"));
          return;
      }

      fetch(getEndpoint() + "/api/v1/user/changePass", {
          method: 'post',
          mode: "cors",
          body: JSON.stringify(user),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
        })
          .then(
            async function (response) {
              try {
                response.json().then(json => {
                  console.log(json);
                  dispatch(updatePasswordSuccess(json.msg, user));
                });
              } catch (e) {
                console.log("error!!")
                dispatch(updatePasswordFailed(e));
              }
            },

            error =>  dispatch(updatePasswordFailed(error))
          )

   
  }
}
export function newPassword(user) {
  console.log(user)
  return async function(dispatch) {
    dispatch(newPasswordStart())
      //Create userø
      if(!user){
          dispatch(newPasswordFailed("udfyld venligst email feltet"));
          return;
      }
      var check = !(user.email);
      console.log({check})
      if(check){
          dispatch(newPasswordFailed("udfyld venligst email feltet"));
          return;
      }

      fetch(getEndpoint() + "/api/v1/user/newPass", {
          method: 'post',
          mode: "cors",
          body: JSON.stringify(user),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
        })
          .then(
            async function (response) {
              try {
                response.json().then(json => {
                  console.log(json);
                  dispatch(newPasswordSuccess(json.msg));
                });
              } catch (e) {
                console.log("error!!")
                dispatch(newPasswordFailed(e));
              }
            },

            error =>  dispatch(newPasswordFailed(error))
          )

   
  }
}

export function createUser(user) {
  console.log(user)
  return async function(dispatch, getState) {
    dispatch(createdUserStart());
      //Create userø
      if(!user){
          dispatch(createdUserFailed("udfyld venligst alle felter"));
          return;
      }
      var check = !(user.name && user.email && user.address && user.password && user.confirmPassword && user.phone && user.postal&& user.town && user.type);
      console.log({check})
      if(check){
          dispatch(createdUserFailed("udfyld venligst alle felter"));
          return;
      }

      fetch(getEndpoint() + "/api/v1/user/createUser", {
          method: 'post',
          mode: "cors",
          body: JSON.stringify(user),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
        })
          .then(
            async function (response) {
              try {
                response.json().then(json => {
                  console.log(json);
                  if(json.status === 500){

                  }else{
                    dispatch(login({email: user.email, password: user.password}));
                  }
                   dispatch(createdUserSuccess(json.msg));
                  console.log("login");
                  
                  
                });
              } catch (e) {
                console.log("error!!")
                dispatch(createdUserFailed(e));
              }
            },

            error =>  dispatch(createdUserFailed(error))
          )

   
  }
}

export function login(user, redirect) {


    return async function(dispatch) {
        console.log(user)
        dispatch(loginUserStart());
        if(!user){
            dispatch(loginUserFailed("udfyld venligst alle felter"));
            return;
        }
        var check = !(user.email && user.password);
        console.log({check})
        if(check){
            dispatch(loginUserFailed("udfyld venligst alle felter"));
            return;
        }
        //Create user
        fetch(getEndpoint() + "/api/v1/user/login", {
            method: 'post',
            mode: "cors",
            body: JSON.stringify(user),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
          })
            .then(
              async function (response) {
                console.log(response); 
                if(response.status === 404){
                  dispatch(loginUserFailed("Ikke korrekt email eller kodeord"));
                  return; 
                }
                try {
                  response.json().then(json => {
                    console.log(json);
                    dispatch(loginUserSuccess(json));
                    if(redirect){
                      dispatch(navigateToPage("USERPAGE"));
                    }
                   
                  });
                } catch (e) {
                  console.log("error!!")
                  console.log(e);
                  dispatch(loginUserFailed("fejl"));
                }
              },

              error =>   dispatch(loginUserFailed("fejl"))
            )

     
    }
}


export const clearMessages = () => ({
  type: 'USER_CLEARMESSAGES',
})
export const newPasswordStart = () => ({
  type: 'PASSWORD_NEW_START',
})

export const newPasswordFailed = (msg) => ({
  type:  'PASSWORD_NEW_FAILED',
  msg
})

export const newPasswordSuccess = (msg) => ({
  type: 'PASSWORD_NEW_SUCCESS',
  msg
})

export const updateUserStart = () => ({
  type: 'USER_UPDATE_START',
})

export const updateUserSuccess = (msg, user) => ({
  type: 'USER_UPDATE_SUCCESS',
  user, 
  msg
})

export const updateUserFailed = (msg) => ({
  type: 'USER_UPDATE_FAILED',
  msg
})

export const updatePasswordStart = () => ({
  type: 'PASSWORD_UPDATE_START',
})


export const updatePasswordSuccess = (msg, user) => ({
  type: 'PASSWORD_UPDATE_SUCCESS',
  user, 
  msg
})

export const updatePasswordFailed = (msg) => ({
  type: 'PASSWORD_UPDATE_FAILED',
  msg
})

export const createdUserStart = () => ({
    type: 'USER_CREATE_START',
  })


export const createdUserSuccess = (msg) => ({
    type: 'USER_CREATE_SUCCESS',
    msg
  })

  export const createdUserFailed = (msg) => ({
    type: 'USER_CREATE_FAILED',
    msg
  })
  
  
export const loginUserStart = () => ({
    type: 'USER_LOGIN_START',
  })


export const  loginUserSuccess = (user) => ({
    type: 'USER_LOGIN_SUCCESS',
    user
  })

  export const  loginUserFailed = (msg) => ({
    type: 'USER_LOGIN_FAILED',
    msg
  })

  export const  logout = () => ({
    type: 'USER_LOGOUT',
  })
  