import React, { Component } from 'react';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import mobilePayLogo from '../images/mobilepay.png'
const style = {
    containerStyle: {
        backgroundColor: "#0d1f22",
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        width: "100%"
    },
    container: {
        padding: 30,
        flex: 1,
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 300
    },
    text: {
        color: "rgb(240,240,240)",
        display: "flex",
        textAlign: "center"

    },
    logo: {
        height: "auto",
        width: 150,
    },
    sideBySide: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
        width: 300
    },
    seperator:{
        display: "flex",
        width: 2, 
        backgroundColor: "rgb(240,240,240)"
    }
}

class Footer extends Component {

    render() {
        return (
            <div style={style.containerStyle}>
                <div style={style.container}>
                    <Typography style={style.text} variant="h5">Hip Hop International Denmark</Typography>
                    <Typography style={style.text} variant="h6">CVR : 26076900</Typography>
                    <Typography style={style.text} variant="h6">Telefon : +45 26493088</Typography>
                    <Typography style={style.text} variant="h6">Kontor : Møllevej 7, 8881 Thorsø</Typography>
                    <Typography style={style.text} variant="h6">Email : info@trygsen.dk</Typography>
                </div>
                <div style={style.container}>
                    <Typography style={style.text} variant="h5">Her kan du betale med: </Typography>
                     <Typography style={style.text} variant="h6">Bank konto</Typography>
                    <Typography style={style.text} variant="h6">Reg: 8117 Konto: 4494746 </Typography>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // todos: getVisibleTodos(state.todos, state.visibilityFilter)
})

const mapDispatchToProps = dispatch => ({
    //  toggleTodo: id => dispatch(toggleTodo(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer)

