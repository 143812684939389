
const users = (state = [], action) => {
    switch (action.type) {
        case 'USER_CREATE_START':
        return Object.assign({}, {}, {
            loading: true
        })
        case 'USER_CREATE_SUCCESS':
        return Object.assign({}, state, {
            loading: false,
            msg: action.msg
        })
        case 'USER_CREATE_FAILED':
        return Object.assign({}, state, {
            loading: false,
            msg: action.msg
        })
        case 'USER_LOGIN_START':
        return Object.assign({}, {}, {
            loading: true
        })
        case 'USER_LOGIN_SUCCESS':
        return Object.assign({}, state, {
            loading: false,
            user: action.user[0]
        })
        case 'USER_LOGIN_FAILED':
        return Object.assign({}, {}, {
            loading: false,
            loginmsg: action.msg
        })
        case 'USER_UPDATE_START':
        return Object.assign({}, state, {
            loading: false,
            updatemsg: ""
        })
        case 'USER_UPDATE_FAILED':
        return Object.assign({}, state, {
            loading: false,
            updatemsg: action.msg
        })
        case 'USER_UPDATE_SUCCESS':
        return Object.assign({}, state, {
            loading: false,
            user: action.user,
            updatemsg: action.msg
        })
        case 'PASSWORD_UPDATE_START':
        return Object.assign({}, state, {
            loading: true,
            changePassMsg: ""
        })
        case 'PASSWORD_UPDATE_SUCCESS':
        return Object.assign({}, state, {
            loading: false,
            changePassMsg: action.msg
        })
        case 'PASSWORD_UPDATE_FAILED':
        return Object.assign({}, state, {
            loading: false,
            changePassMsg: action.msg
        })
        case 'PASSWORD_NEW_START':
        return Object.assign({}, state, {
            loading: true,
            loginmsg: ""
        })
        case 'PASSWORD_NEW_SUCCESS':
        return Object.assign({}, state, {
            loading: false,
            loginmsg: action.msg
        })
        case 'PASSWORD_NEW_FAILED':
        return Object.assign({}, state, {
            loading: false,
            loginmsg: action.msg
        })
        case 'USER_CLEARMESSAGES':
        return Object.assign({}, state, {
            loginmsg: "",
            changePassMsg: "",
            updatemsg: ""
        })
        
        case 'USER_LOGOUT':
        return Object.assign({}, {}, {
      
        })

        

      default:
        return state
    }
  }
  
  export default users