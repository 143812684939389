
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-139337322-1');
const style = {
    width: '350px',
    marginLeft: '20px',
    marginTop: '20px',
    display: 'inline-block'
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    async componentDidCatch(error, info) {
        this.setState({
            hasError: true
        });

        //Clean redux state
        ReactGA.event({
            category: 'CRASH',
            action: info,
            label: error
            });
        await this.props.refreshState();
        window.history.pushState({}, "", "");
        setTimeout(() => window.location.reload(), 1000);
    }
    render() {
        if (this.state.hasError) {
            {  this.props.refreshState();}
            return (
                <h1>Der gik noget galt, genindlæser siden....</h1>
            );
        }
        return this.props.children;
    }
}


const mapStateToProps = state => ({
    // todos: getVisibleTodos(state.todos, state.visibilityFilter)
})

const mapDispatchToProps = dispatch => ({
    refreshState: () => dispatch(
        {
            type: "RESET_APP",

        })
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorBoundary)
