import React, { Component, Suspense } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TrygsenLogo from "../images/Logo.png";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/HomeSharp";
import PersonAddIcon from "@material-ui/icons/PersonAddSharp";
import PersonIcon from "@material-ui/icons/AccountCircleSharp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCartSharp";
import HelpIcon from "@material-ui/icons/HelpSharp";
import InfoIcon from "@material-ui/icons/InfoSharp";
import ContactMailIcon from "@material-ui/icons/PhoneSharp";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasketSharp";
import MenuIcon from "@material-ui/icons/MenuSharp";
import { connect } from "react-redux";
import { navigateToPage } from "../_actions/Navigation";
import { Transform } from "react-animation-components";
import Dialog from "@material-ui/core/Dialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { getProducts } from "../_actions/Products";
import Signup from "../components/Signup";
import Signin from "../components/Signin";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ShoppingBasket from "../components/ShoppingBasket";
import CookieConsent from "react-cookie-consent";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Offers from "./Offers";
import Judges from "./Judges";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*
import WebStore from './WebStore'
import UserPage from './UserPage'
import FrontPage from './FrontPage'
import Checkout from './Checkout'
import Contact from './Contact'
import Info from './UsefullInfo'
import PDFComponent from '../components/PDFreader'
*/
import { SET_PDFDATA } from "../_actions/pdf";

//Google analytics
import ReactGA from "react-ga";
ReactGA.initialize("UA-139337322-1");
ReactGA.pageview(window.location.pathname + window.location.search);
const UserPage = React.lazy(() => import("./UserPage"));
const FrontPage = React.lazy(() => import("./FrontPage"));
const WebStore = React.lazy(() => import("./WebStore"));
const Checkout = React.lazy(() => import("./Checkout"));
const Contact = React.lazy(() => import("./Contact"));
const Info = React.lazy(() => import("./UsefullInfo"));
const About = React.lazy(() => import("./About"));
const Results = React.lazy(() => import("./Results"));
const Signupteam = React.lazy(() => import("./Signup"));
const PDFComponent = React.lazy(() => import("../components/PDFreader"));

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {},
  toolbar: {
    flex: 1,
    width: "97.5%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  appBar: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 6,
    height: 60
  },

  menuButtons: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0
  },
  menuButton: {
    fontSize: 20,
    borderRadius: 0,
    padding: 5
  },
  userButton: {
    fontSize: 20,
    marginRight: 6
  },
  logo: {
    alignSelf: "flex-start",
    width: 300,
    marginTop: 6,
    marginBottom: -60
  },
  menuIcon: {
    fontSize: 20,
    margin: 5
  },
  shoppingBasket: {
    position: "absolute",
    zIndex: 5
  },
  container: {
    display: "flex",
    flex: 1,
    padding: 30,
    flexFlow: "column"
  },
  text: {
    textAlign: "center"
  },
  checkbox: {
    alignSelf: "center"
  },
  order: {
    alignSelf: "center",
    width: 300
  },
  userDialog: {
    width: 500
  },
  userCreateLogin: {
    minHeight: 300,
    width: "100%",
    overflowX: "hidden",
    flexFlow: "column",
    justifyContent: "center"
  },
  navText: {
    color: "white",
    fontSize: 15
  },
  navTextPhone: {
    color: "black",
    fontSize: 15
  },
  navigatorContainer: {
    display: "flex",
    height: 60,
    flex: 1,
    flexFlow: "row"
  },
  trygsenLogoContainer: {
    height: 60
  },
  trygsenLogo: {
    height: 100,
    marginTop: -20,
    marginLeft: -71
  },
  trygsenLogoTallerken: {
    height: 85,

    margin: 0
  },
  logoText: {
    color: "white",
    fontSize: 20
  }
};

const DialogNoMargin = withStyles(theme => ({
  paper: {
    margin: 0
  },
  root: {
    margin: 0
  }
}))(Dialog);
var previousPage = <div>loading</div>;
class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoppingBasket: false,
      newItem: false,
      openUser: false,
      value: 0,
      anchorEl: null
    };
    this.triggerBasketAnimation = this.triggerBasketAnimation.bind(this);
  }

  componentDidMount(props) {
    this.props.getProducts();
    //Reset pdf data
    this.props.SET_PDFDATA("");
    window.onpopstate = () => {
      this.forceUpdate();
      //Navigate to URL;
      console.log(window.location.href.split("/")[3]);
      //Main page
      var page = window.location.href.split("?")[0].split("/")[3];

      if (page) {
        this.props.navigate(page);
      }
    };

    //Navigate to URL;
    console.log(window.location.href.split("/")[3]);
    //Main page
    var page = window.location.href.split("?")[0].split("/")[3];

    if (page) {
      this.props.navigate(page);
    }
  }
  openShoppingBasket() {
    this.setState({
      ...this.state,
      shoppingBasket: !this.state.shoppingBasket
    });
  }

  async triggerBasketAnimation() {
    this.setState({ ...this.state, newItem: true });
    setTimeout(
      async function() {
        this.setState({ ...this.state, newItem: false });
      }.bind(this),
      500
    );
  }
  renderPage(basketAnimation) {
    if (this.props.page == "FRONTPAGE") {
      previousPage = <FrontPage openUser={this.handleClickOpen}></FrontPage>;
      return previousPage;
    } else if (this.props.page == "WEBSTORE") {
      previousPage = (
        <WebStore animation={this.triggerBasketAnimation}></WebStore>
      );
      return previousPage;
    } else if (this.props.page == "CHECKOUT") {
      previousPage = (
        <Checkout
          openUser={this.handleClickOpen}
          animation={this.triggerBasketAnimation.bind(this)}
        ></Checkout>
      );
      return previousPage;
    } else if (this.props.page == "USERPAGE") {
      previousPage = (
        <UserPage animation={this.triggerBasketAnimation.bind(this)}></UserPage>
      );
      return previousPage;
    } else if (this.props.page == "ABOUT") {
      previousPage = (
        <About animation={this.triggerBasketAnimation.bind(this)}></About>
      );
      return previousPage;
    } else if (this.props.page == "CONTACT") {
      previousPage = (
        <Contact animation={this.triggerBasketAnimation.bind(this)}></Contact>
      );
      return previousPage;
    } else if (this.props.page == "INFO") {
      previousPage = <Info></Info>;
      return previousPage;
    } else if (this.props.page == "OFFERS") {
      previousPage = <Offers openUser={this.handleClickOpen}></Offers>;
      return previousPage;
    } else if (this.props.page == "SIGNUP") {
      previousPage = <Signupteam openUser={this.handleClickOpen}></Signupteam>;
      return previousPage;
    } else if (this.props.page == "JUDGES") {
      previousPage = <Judges></Judges>;
      return previousPage;
    } else if (this.props.page == "RESULTS") {
      previousPage = <Results></Results>;
      return previousPage;
    } else {
      previousPage = <FrontPage openUser={this.handleClickOpen}></FrontPage>;
      return previousPage;
    }
  }

  handleClickOpen = value => {
    var setValue = value ? value : this.state.value;
    this.props.user
      ? this.navigate("USERPAGE")
      : this.setState({ openUser: true, value: setValue });
  };

  handleCloseUser = () => {
    this.setState({ openUser: false });
  };
  handleChange = (event, value) => {
    this.setState({ value });
    console.log(value);
  };

  handleClick = event => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  navigate(path) {
    window.history.pushState("", "", "/" + path);
    this.props.navigate(path);
  }

  getTotalProducs() {
    var total = 0;
    for (var i = 0; i < this.props.products.length; i++) {
      total += Number(this.props.products[i].quantity);
    }

    return total;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products.length == 0) {
      if (this.getTotalProducs() > 0) {
        if (!this.state.shoppingBasket) {
          this.openShoppingBasket();
        }
      }
    }
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <ToastContainer />

        <AppBar position="fixed" style={styles.appBar}>
          {this.props.pdf ? (
            <div>
              <PDFComponent
                data={this.props.pdf}
                handleClose={() => this.props.SET_PDFDATA("")}
              ></PDFComponent>
            </div>
          ) : null}
          <Toolbar style={styles.toolbar}>
            <div style={styles.menuButtons}>
              {isBrowser && window.innerWidth > 1300? (
                <div style={styles.navigatorContainer}>
                  <div style={styles.trygsenLogoContainer}>
                    <img
                      src={TrygsenLogo}
                      style={styles.trygsenLogoTallerken}
                    />
                  </div>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("FRONTPAGE")}
                  >
                    <HomeIcon style={styles.menuIcon}> </HomeIcon>
                    <Typography style={styles.navText}>Forside</Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("RESULTS")}
                  >
                    <HelpIcon style={styles.menuIcon}> </HelpIcon>

                    <Typography style={styles.navText}>Resultater</Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("SIGNUP")}
                  >
                    <PersonAddIcon style={styles.menuIcon}> </PersonAddIcon>
                    <Typography style={styles.navText}>Tilmeld</Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("JUDGES")}
                  >
                    <InfoIcon style={styles.menuIcon}> </InfoIcon>
                    <Typography style={styles.navText}>Dommere </Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("INFO")}
                  >
                    <HelpIcon style={styles.menuIcon}> </HelpIcon>

                    <Typography style={styles.navText}>Info</Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("OFFERS")}
                  >
                    <InfoIcon style={styles.menuIcon}> </InfoIcon>
                    <Typography style={styles.navText}>
                      Overnatning og mad.{" "}
                    </Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("ABOUT")}
                  >
                    <InfoIcon style={styles.menuIcon}> </InfoIcon>

                    <Typography style={styles.navText}>Om os</Typography>
                  </IconButton>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => this.navigate("CONTACT")}
                  >
                    <ContactMailIcon style={styles.menuIcon}> </ContactMailIcon>
                    <Typography style={styles.navText}>Kontakt</Typography>
                  </IconButton>
                </div>
              ) : (
                <div>
                  <IconButton
                    style={styles.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={this.handleClick}
                  >
                    <MenuIcon style={styles.menuIcon}> </MenuIcon>
                  </IconButton>

                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("FRONTPAGE")}
                      >
                        <HomeIcon style={styles.menuIcon}> </HomeIcon>
                        <Typography style={styles.navTextPhone}>
                          Forside
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("RESULTS")}
                      >
                        <HelpIcon style={styles.menuIcon}> </HelpIcon>
                        <Typography style={styles.navTextPhone}>
                          Resultater
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("SIGNUP")}
                      >
                        <PersonAddIcon style={styles.menuIcon}> </PersonAddIcon>
                        <Typography style={styles.navTextPhone}>
                          Tilmeld
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("WEBSTORE")}
                      >
                        <ShoppingCartIcon style={styles.menuIcon}>
                          {" "}
                        </ShoppingCartIcon>
                        <Typography style={styles.navTextPhone}>
                          Merchandise
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("INFO")}
                      >
                        <HelpIcon style={styles.menuIcon}> </HelpIcon>
                        <Typography style={styles.navTextPhone}>
                          Info
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("ABOUT")}
                      >
                        <InfoIcon style={styles.menuIcon}> </InfoIcon>

                        <Typography style={styles.navTextPhone}>
                          Om os
                        </Typography>
                      </IconButton>
                    </MenuItem>

                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("CONTACT")}
                      >
                        <ContactMailIcon style={styles.menuIcon}>
                          {" "}
                        </ContactMailIcon>

                        <Typography style={styles.navTextPhone}>
                          {" "}
                          Kontakt
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("OFFERS")}
                      >
                        <InfoIcon style={styles.menuIcon}> </InfoIcon>

                        <Typography style={styles.navTextPhone}>
                          {" "}
                          Overnatning og mad.
                        </Typography>
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <IconButton
                        style={styles.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.navigate("JUDGES")}
                      >
                        <InfoIcon style={styles.menuIcon}> </InfoIcon>

                        <Typography style={styles.navTextPhone}>
                          {" "}
                          Dommere
                        </Typography>
                      </IconButton>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>

            <Transform
              enterTransform="scale(1.2)"
              exitTransform="scale(1)"
              in={this.state.newItem}
              duration={100}
              delay={0}
            >
              <IconButton
                style={styles.userButton}
                color="inherit"
                aria-label="Menu"
                onClick={() => this.openShoppingBasket()}
              >
                <ShoppingBasketIcon style={styles.userButton}>
                  {" "}
                </ShoppingBasketIcon>
                <Typography style={styles.navText}>
                  {" "}
                  {this.getTotalProducs()}
                </Typography>
              </IconButton>
            </Transform>

            <IconButton
              style={styles.userButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => this.handleClickOpen()}
            >
              <PersonIcon style={styles.userButton}> </PersonIcon>

              <Typography style={styles.navText}>
                {" "}
                {this.props.profile ? this.props.profile.name : "log ind"}
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="right"
          open={this.state.shoppingBasket}
          variant="persistent"
          style={styles.shoppingBasket}
        >
          <ShoppingBasket
            animation={this.triggerBasketAnimation}
            toggleShoppingBasket={this.openShoppingBasket.bind(this)}
          />
        </Drawer>

        <Suspense fallback={<div> </div>}>{this.renderPage()}</Suspense>

        <DialogNoMargin
          open={!this.props.user && this.state.openUser}
          onClose={this.handleCloseUser}
          aria-labelledby="form-dialog-title"
          m={0}
        >
          <DialogContent
            style={{
              marginLeft: 0,
              maxHeight: 600,
              maxWidth: 600,
              minWidth: 280,
              padding: 0
            }}
          >
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Opret bruger" />
              <Tab label="Login" />
            </Tabs>
            <div style={styles.userCreateLogin}>
              {this.state.value == 0 ? <Signup /> : null}
              {this.state.value == 1 ? <Signin /> : null}
            </div>
          </DialogContent>
        </DialogNoMargin>

        <CookieConsent
          location="bottom"
          buttonText="Okay"
          cookieName="TrygsenCookie"
          style={{ background: "#0E2023" }}
          buttonStyle={{
            backgroundColor: "#681500",
            color: "white",
            fontSize: "15px"
          }}
          expires={150}
        >
          Denne hjemmeside bruger cookies til at forbedre bruger oplevelsen.
          Vores persondata politik kan ses under info
        </CookieConsent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Users.user ? true : false,
  profile: state.Users.user,
  page: state.Navigation.page,
  products: state.ShoppingCart.products,
  cart: state.ShoppingCart,
  pdf: state.pdf.data
});

const mapDispatchToProps = dispatch => ({
  //  toggleTodo: id => dispatch(toggleTodo(id))
  navigate: page => dispatch(navigateToPage(page)),
  getProducts: () => dispatch(getProducts()),
  SET_PDFDATA: data => dispatch(SET_PDFDATA(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
